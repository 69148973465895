// -------------------------------------------------------------------------------------------
// Post Grid
// -------------------------------------------------------------------------------------------

(function($)
{ 
	"use strict";

        
	$.fn.postGrid = function(options)
	{
        
		//return if we didnt find anything
		if(!this.length) return this;
		
		var methods = {
		
			show_bricks: function(bricks, callback, newlyLoaded)
			{
				bricks.each(function(i)
				{
					var currentLink = $(this);

					if(newlyLoaded) {
						currentLink.addClass('newly-loaded');
					}else{
						currentLink.addClass('already-loaded');
					}

					setTimeout(function()
					{
						if(i == bricks.length - 1 && typeof callback == 'function')
						{
							callback.call();
						}
						
					}, 100);
				});
			},
			
			loadMore: function(e)
			{
				e.preventDefault();
			
				var current		= $(this),
					masonry 	= current.parent().prev('.posts-load-more'),
					aSingleColumn = masonry.children(".column").first().outerHeight(),
					scrollTopValue  = (masonry.offset().top + masonry.outerHeight()) - aSingleColumn - 80,
			  		data		= current.data();
			  			  	
			  	//calculate a new offset	
			  	if(!data.offset){ data.offset = 0; }	
			  	data.offset += data.items;
			  	data.action = 'ep_post_grid_more';
				  
				current.text("Loading...");
			  	$.ajax({
					url: avia_framework_globals.ajaxurl,
					type: "POST",
					data: data,
					success: function(response)
					{

						$('html, body').animate({
							scrollTop: scrollTopValue
						}, 50);
		
						current.text("Load More");
                        
						if(response.indexOf("{post-grid-loaded}") !== -1)
						{
							    //fetch the response. if any php warnings were displayed before rendering of the items the are removed by the string split
							    var response  = response.split('{post-grid-loaded}'),
                                new_items = $(response.pop()).filter('.column');
                            
								
								//check if we got more items than we need. if not we have reached the end of items
								if(new_items.length > data.items)
								{
									new_items = new_items.not(':last');
								}
								else
								{
									current.addClass('av-no-more-items');
								}
								
								masonry.append(new_items);
								setTimeout( function(){ methods.show_bricks( new_items, false, true ) }, 50);

								
								
						}
					},
				});
            }
            
        };
        

        return this.each(function() {
			var grid			= $(this),
                container       = $(this).parent(),
                bricks		    = grid.find('.column'), 
                load_more       = container.find('.ajax-load-more').css({visibility:"visible", opacity:0});

            methods.show_bricks(bricks, function()
            {
				load_more.css({opacity:1}).on('click', methods.loadMore);

            }, false);
        });

	
	};

	
}(jQuery));
